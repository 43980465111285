import './index.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import Flag from 'react-world-flags';
import { useLocation, useNavigate } from 'react-router-dom';

const LANG_STORAGE_KEY = 'lang';

const LanguageSelector =  () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [languages, setLanguages] = useState<any>(null);
  const [lang, setLang] = useState<string>(i18n.language);

  useEffect(() => {
    const getLanguages = async () => {
      const response = await fetch('json/languages.json');
      const _languages: any[] = await response.json();
      setLanguages(_languages);
    }
    getLanguages();
  }, []);

  const handleChange = (langCode: string) => {
    console.log(langCode);
    i18n.changeLanguage(langCode);
    localStorage.setItem(LANG_STORAGE_KEY, langCode);
    if (location.pathname.startsWith('/builder')) {
      window.location.reload();
    }
  }

  const getFlag = (langCode: string): string => {
    const _lang = languages.find((lang: any) => lang.value === langCode);
    return _lang ? _lang?.flag : null;
  }

  const labelRender = (props: any) => {
  const { label, value } = props;
  return (
    <>
      <div className='lang-item'>
      <Flag code={getFlag(value)}
            height="16"
            style={{width: '32px'}}
      />
      <span>{label}</span>
      </div>
    </>
  );
};

  return (
    <>
    {(languages && i18n.language) && (
      <Select
        style={{ width: 150 }}
        defaultValue={i18n.language}
        labelRender={labelRender}
        optionRender={labelRender}
        onChange={handleChange}
        options={languages} />
      )}
    </>
  );
}
// [{ value: 'sample', label: <span>sample</span> }]
export default LanguageSelector