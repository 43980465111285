import React, { useEffect, useRef, useState } from 'react';
import { useCart } from '../CartContext';
import { Card, Col, Form, Input, Modal, Row, Radio, FloatButton, Result } from 'antd';
import './index.scss';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownloadOutlined, EditOutlined,
} from '@ant-design/icons';
import { CartItem } from '../../db';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import Reaptcha from 'reaptcha';
import { useTranslation } from 'react-i18next';
import { useProduct } from '../ProductContext';

const HEADLIINE: any = {
  'TAK': 'email_headline_yes',
  'NIE': 'email_headline_no'
}

interface CreateFormProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onCreate: (values: any, captchaToken: string | null) => void;
}

const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}


const CreateForm = (props: CreateFormProps) => {
  const {visible, setVisible, onCreate} = props;
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const captchaRef = useRef<Reaptcha | null>(null);
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    setIsChecked(event.target.value === 'TAK');
  };

  const verify = async () => {
    if (captchaRef.current) {
      const res = await captchaRef.current.getResponse();
      setCaptchaToken(res);
    }
  };

  const handleSubmitOrder = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        setIsChecked(false);
        onCreate(values, captchaToken);
      })
      .catch((info) => {
        setIsChecked(false);
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      visible={visible}
      title={t('order')}
      okText={t('ok')}
      cancelText={t('cancel')}
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleSubmitOrder}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('name_and_surname')}
          name="name"
          rules={[
            {required: true, message: t('required_field')}
          ]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label={t('email')}
          name="email"
          rules={[
            {required: true, message: t('required_field')}
          ]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label={t('phone_number')}
          name="phone"
          rules={[
            {required: true, message: t('required_field')}
          ]}
        >
          <Input/>
        </Form.Item>

        <Form.Item name="info" label={t('additional_info')}>
          <Input.TextArea/>
        </Form.Item>

        <Form.Item name="montage" label={t('with_installation')}>
          <Radio.Group defaultValue={'NIE'}
                       onChange={handleChange}>
            <Radio value={"TAK"}>{t('yes')}</Radio>
            <Radio value={"NIE"}>{t('no')}</Radio>
          </Radio.Group>
        </Form.Item>

        {isChecked && (
          <div style={{ display: "flex", gap: "8px" }}>
            <Form.Item
                label={t('post_code')}
                name="postcode"
                style={{ flex: '0 0 120px' }}
                rules={[
                  {required: true, message: t('required_field')}
                ]}
              >
                <Input maxLength={6}/>
            </Form.Item>
            <Form.Item
              label={t('address')}
              name="address"
              style={{ flex: '1 1 100%' }}
              rules={[
                {required: true, message: t('required_field')}
              ]}
            >
              <Input/>
            </Form.Item>
          </div>
        )}

        <Reaptcha
          sitekey={'6LdsqyAqAAAAAAjtqxp2oQv0KKAF18XIVJpEg_LQ'}
          ref={captchaRef}
          onVerify={verify}
        />

      </Form>
    </Modal>
  );
};


export const Cart = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {items, deleteItem, formattedCartTotals, getItemById, cartTotal, formatAmount} = useCart();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const [headline, setHeadline] = useState<string>(HEADLIINE['NIE']);
  const { selectProductByType, clearProduct } = useProduct();

  useEffect(() => {
    clearProduct();
  }, []);

  const onCreate = async (values: any, captchaToken: string | null) => {
    setVisible(false);
    setIsLoading(true);

    setHeadline(HEADLIINE[values?.montage || 'NIE'])

    const currentDate = new Date();

    const lang = (): string => {
      return i18n.language;
    }

    const content = {
      ...values,
      montage: values?.montage || 'NIE',
      totalPrice: new Intl.NumberFormat(lang(), { style: 'currency', currency: 'PLN' }).format(cartTotal),
      timestamp: formatDate(currentDate),
      items: items.map((item: CartItem) => ({
        name: item.name,
        img: item.image?.url,
        price: new Intl.NumberFormat(lang(), { style: 'currency', currency: 'PLN' }).format(item.price)
      }))
    }

    const data: FormData = new FormData();
    data.append('content', JSON.stringify(content));
    data.append('g-recaptcha-response', captchaToken || '');

    items.forEach((item: CartItem) => {
      data.append('attachments[]', item.doc, `${item.name}.pdf`);
    });

    const response: Response = await fetch('/send_email.php', {
      method: 'POST',
      mode: 'no-cors',
      body: data,
    });

    setIsLoading(false);
    setSuccessVisible(true);
  };

  const removeCartItem = async (id: number | undefined) => {
    if (id) {
      await deleteItem(id);
    }
  }

  const goBack = () => {
    navigate('/');
  }

  const edit = (item: CartItem | undefined) => {
    if (item) {
      selectProductByType(item?.type);
      navigate(`/builder/${item?.configId}`);
    }
  }

  const download = (id: number | undefined) => {
    if (id) {
      const item: CartItem | null = getItemById(id);
      if (item && item.doc) {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = URL.createObjectURL(item.doc);
        link.download = t(`products.${item.name}`) + '.pdf';
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );
      }
    }
  }

  const sendOrder = () => {
    setVisible(true);
  }


  return (
    <>
      <div className="shopping-cart">
        <h1>{t('your_shopping_cart')}</h1>

        {items && items.length ? (

          <Row gutter={16}>
            {
              items.map((item: CartItem) => {
                return (
                  <Col span={4}>
                    <Card
                      hoverable
                      className={'cart-item'}
                      actions={
                        item.configId ?
                        [
                          <DownloadOutlined key="download" onClick={event => {
                            event.stopPropagation();
                            download(item.id)
                          }}/>,
                          <EditOutlined key="edit" onClick={event => {
                            event.stopPropagation();
                            edit(item)
                          }}/>,
                          <DeleteOutlined key="delete" onClick={event => {
                            event.stopPropagation();
                            removeCartItem(item.id)
                          }}/>,
                        ] : [
                          <DownloadOutlined key="download" onClick={event => {
                            event.stopPropagation();
                            download(item.id)
                          }}/>,
                          <DeleteOutlined key="delete" onClick={event => {
                            event.stopPropagation();
                            removeCartItem(item.id)
                          }}/>,
                        ]
                      }
                      cover={<img alt="example" src={item.image.url}/>}>

                      <Card.Meta
                        title={t(`products.${item.name}`)}
                        description={
                          <>
                            <span className={'cart-item-price'}>{formatAmount(item.price)}</span>
                          </>
                        }
                      />
                    </Card>
                  </Col>
                )
              })
            }
          </Row>
        ) : (
          <h2 className={'emtpy-cart-info'}>{t('empty_cart')}</h2>
        )
        }

      </div>
      <div className="price cart-total">
        <span>{t('your_shopping_cart')}</span>
        {formattedCartTotals()}
      </div>
      <div className="go-back"
           onClick={goBack}>
        <ArrowLeftOutlined/>
      </div>

      <CreateForm
        visible={visible}
        setVisible={setVisible}
        onCreate={onCreate}
      />


      <Modal
      visible={successVisible}
      okText="Ok"
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => setSuccessVisible(false)}
    >
      <Result
        status="success"
        title={t('order_sent')}
        subTitle={t(headline)}
      />
    </Modal>



      {items && items.length && (
        <FloatButton type="primary"
                     description={t('send_order')}
                     style={{right: 24, bottom: 15, width: 80, height: 80}}
                     onClick={sendOrder}/>
      )}
      <Loading isLoading={isLoading}></Loading>
    </>)
}