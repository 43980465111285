import './App.scss';
import React, { useState } from 'react'
import AppHeader from './components/appHeader';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './components/AppRoutes';
import { useTranslation } from 'react-i18next';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "variantic-player": {
        "component-id": string;
        ref: any;
        width: string;
        height: string;
      };
    }
  }
}


function App() {

  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState<string>(i18n.language);

  return (
    <>
      {/*<BrowserRouter>*/}
      <BrowserRouter>
      <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#fec526',
          borderRadius: 2,
          colorBgContainer: '#ffffff',
        },
      }}
    >
        <div className="App">
          <div className="header">
            <AppHeader></AppHeader>
          </div>
          <div className="container">
            <AppRoutes></AppRoutes>
          </div>
          <div className="terms"><a href={'https://morad.pl/' + i18n.language + '/ogolne-warunki-sprzedazy-i-gwarancji'} target="_blank">{t('terms')}</a></div>
        </div>
      </ConfigProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
