import React, { createContext, useContext, useState, useEffect } from 'react';

interface ProductContextContextType {
  selectedProduct: any | undefined;
  allProducts: any[];
  selectProduct: (id: number) => any;
  selectProductByType: (type: number) => any;
  clearProduct: () => void;
  selectedProductStorage: any;
}

interface Props {
  children: React.ReactNode;
}

const SELECTED_PRODUCT_KEY = 'selected-product';

const ProductContext = createContext<ProductContextContextType | undefined>(undefined);

export const ProductProvider: React.FC<Props>= ({ children }) => {
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();

  useEffect(() => {
    const getProducts = async () => {
      const response = await fetch('json/products.json');
      const _products: any[] = await response.json();
      setAllProducts(_products);
      setLoaded(true);
    }
    getProducts();
  }, []);

  useEffect(() => {
    if (loaded) {
      const _prod = localStorage.getItem(SELECTED_PRODUCT_KEY);
      if (_prod) {
        selectProduct(JSON.parse(_prod).id);
      }
    }
  }, [loaded]);

  const selectedProductStorage = () => {
    const _prod = localStorage.getItem(SELECTED_PRODUCT_KEY);
    if (_prod) {
      const _prodData = JSON.parse(_prod);
      selectProduct(_prodData);
      return _prodData;
    } else {
      return null;
    }

  }

  const selectProductByType = (type: number): any => {
    const _product = allProducts.find(product => product.componentId === type);
    if (_product) {
      localStorage.setItem(SELECTED_PRODUCT_KEY, JSON.stringify(_product));
      setSelectedProduct(_product);
    }
    return _product;
  }

  const selectProduct = (id: number): any => {
    const _product = allProducts.find(product => product.id === id);
    if (_product) {
      localStorage.setItem(SELECTED_PRODUCT_KEY, JSON.stringify(_product));
      setSelectedProduct(_product);
    }
    return _product;
  };

  const clearProduct = () => {
    localStorage.removeItem(SELECTED_PRODUCT_KEY);
    setSelectedProduct(null);
  }

  return (
    <ProductContext.Provider value={{ selectedProduct, allProducts, selectProduct, clearProduct, selectedProductStorage, selectProductByType }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProduct must be used within a ProductProvider');
  }
  return context;
};